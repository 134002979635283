import { graphql,Link } from 'gatsby';
import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

export const pageQuery = graphql`

query {

    allStrapiPageData(filter: {page_name: {eq: "license_numbers"}}) {
      edges {
        node {
          id
          page_name
          style_category
          content
          attribute_1_key
          attribute_1_value
          attribute_2_key
          attribute_2_value
          attribute_3_key
          attribute_3_value
          attribute_4_key
          attribute_4_value
          attribute_5_key
          attribute_5_value
          column
          data_category
          version
        }
    }
  }
  }
    `;

const LicenseNumbers = ( { data } ) => {
  return (
    <Layout header={'menu'}>
      <SEO
        title="FAQ Page"
        description="FAQ Page description"/>

      <div className="flexColumn carrierCommonPage">
        <div className = "company-info-container container-fluid">
          <div className="row">
            <div className="col-lg-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <div>
                <h1 className="carrier-plan-heading about-heading" style={{ lineHeight: '1.55', textAlign: 'center' }}>
                     License numbers
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className = "container">
        <div className = "row">
          <div className = "col-lg-3">
          </div>

          <div className = "col-lg-6 license-padding">
            <table className = "table">
              <tbody>
                {
                  data.allStrapiPageData.edges.map( ( d,i ) => {
                    return(
                      <tr key={i} className = "style-table-border">
                        <td>
                          {d.node.attribute_1_value}
                        </td>

                        <td className = "align-second-col">
                          {d.node.attribute_3_value}
                        </td>
                      </tr> );
                  } )}
              </tbody>
            </table>

          </div>

          <div className = "col-lg-3">
          </div>
        </div>
      </div>
    </Layout>

  ); };

export default LicenseNumbers;
